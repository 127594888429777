<template>
    <div id="root">
        <router-view id="router-view" />
    </div>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import { merge } from 'lodash';
import Vue from 'vue';
import cleverbridge from '@core/mixins/cleverbridge.js';
import utm from '@core/mixins/utm.js';
import * as settings from '@model/const/events';
import { LOCALE_TO_COUNTRY_MAP } from '@utils/locales';
import 'the-new-css-reset/css/reset.css'; // eslint-disable-line import/no-extraneous-dependencies
import '@core/styles/vars.pcss';
import '@core/styles/defaults.pcss';
import '@core/styles/vars/grid-system.scss';
import '@core/styles/spacings.scss';
import '@core/styles/cols.scss';
import '@core/styles/custom-fonts.scss';
import '@core/styles/bundle-corp-uikit.scss';
// NB: font stylesheet is preload + async import in root.html for better UX

declare global {
    interface Window {
        google: any;
        markerClusterer: any;
        dataLayer: any;
        splitsCurrentActiveSet: any;
        ga: any;
        willRedirectionOccurByVWO: any;
        VWO_EVENT_STATE_CANCELED: string;
        VWO_EVENT_STATE_PENDING: string;
        VWO_EVENT_STATE_DONE_REDIRECT: string;
        VWO_EVENT_STATE_DONE_REGULAR: string;
        VWO_EVENT_STATE_HANDLED: string;
        VWO_EVENT_SESSION_KEY: string;
        vwoEventState: string | undefined;
    }
}

export default Vue.extend({
    name: 'AppRoot',

    mixins: [utm, cleverbridge],

    data: () => ({
        vwoAttempts: 0,
    }),

    computed: {
        page() {
            return this.$store.state.pages?.page || {};
        },

        events() {
            return this.$store.state.pages?.page?.settings?.pushEventToDataLayer || [];
        },

        getUserID() {
            return Cookies.get('AcronisUID.en');
        },

        dimensions() {
            const splitsDimensionsPairs = (window.splitsCurrentActiveSet || [])
                .filter((x) => Boolean(x.dimension))
                .map((x) => [x.dimension, `${x.id}.${x.variant}`]);

            return { ...Object.fromEntries(splitsDimensionsPairs) };
        },

        getBlogPostGaParams() {
            const blogPost = this.$store.state.blog?.post?.items?.[0];
            if (!blogPost) return {};

            return {
                DL_PageType: blogPost.page_type?.name,
                DL_Audience: blogPost.page_audience?.name,
            };
        },

        getResourceCenterParams() {
            const pageName = this.$route?.name;
            let pageType;
            let audience;
            let product;

            switch (pageName) {
                case 'resource-center-home':
                case 'resource-center-category':
                case 'resource-center-category-page':
                case 'resource-center-tag':
                case 'resource-center-tag-page':
                case 'resource-center-search':
                case 'resource-center-search-page': {
                    audience = 'Generic';
                    pageType = 'Resource center';
                    break;
                }
                case 'resource-center-audience':
                case 'resource-center-audience-page': {
                    const audiences = this.$store.state.resourceCenter?.audiences?.items || [];
                    audience = this.convertResourceCenterAudience(audiences);
                    pageType = 'Resource center';
                    break;
                }
                case 'resource-center-resource': {
                    const resource = this.$store.state.resourceCenter?.resource?.items?.[0];
                    if (!resource) return {};
                    audience = this.convertResourceCenterAudience(resource.audiences || []);
                    pageType = resource.type?.title;
                    product = resource.products?.map((x) => x.name).join(', ');
                    break;
                }
                default: break;
            }

            return {
                DL_PageType: pageType,
                DL_Audience: audience,
                DL_Product: product,
            };
        },

        getEventAudienceGaParams() {
            const event = this.$store.state.events?.event?.items?.[0];
            if (!event) return {};

            const eventAudiences = event.audiences?.map((item) => item.id) || [];
            const sp = eventAudiences.includes(settings.AUDIENCES_ID.serviceProviders);
            const resellers = eventAudiences.includes(settings.AUDIENCES_ID.resellers);
            const business = eventAudiences.includes(settings.AUDIENCES_ID.business);
            const home = eventAudiences.includes(settings.AUDIENCES_ID.home);

            let audiences = 'General';
            if ((sp || resellers) && !business && !home) {
                audiences = 'Cloud';
            } else if (home && !sp && !resellers && !business) {
                audiences = 'Consumer';
            } else if (business && !sp && !resellers && !home) {
                audiences = 'Corporate';
            } else if (sp && business && !home && !resellers) {
                audiences = 'Split';
            }

            return { DL_Audience: audiences };
        },

        getPageGaParams() {
            const page = this.$store.state.pages?.page;
            if (!page) return {};
            const audiences = this.$store.state.pages?.audiences?.items;
            const types = this.$store.state.pages?.types?.items;
            const pageAudience = audiences?.find((el) => el.id === page.audience_id) || {};
            const pageType = types?.find((el) => el.id === page.type_id) || {};

            return {
                DL_PageType: pageType?.name,
                DL_Audience: pageAudience?.name,
                DL_Product: this.$store.state.products?.items?.[page.product_id]?.name,
            };
        },

        getRcGaParams() {
            const resource = this.$store.state.resourceCenter?.resource.items?.[0];
            return {
                rcProduct: resource?.products?.[0]?.name || '(not set)',
                rcFeatured: resource ? Boolean(resource.is_featured) : '(not set)',
                rcCategory: resource?.type.slug || '(not set)',
                // eslint-disable-next-line no-nested-ternary
                rcType: resource ? (resource.is_gated ? 'Gated' : 'Ungated') : '(not set)',
            };
        },

        getMergedDlGaParams() {
            const params = [
                this.getPageGaParams,
                this.getResourceCenterParams,
                this.getEventAudienceGaParams,
                this.getBlogPostGaParams,
            ];
            return merge({
                DL_PageType: '(not set)',
                DL_Audience: '(not set)',
                DL_Product: '(not set)',
            }, ...params);
        },

        getUserInfo() {
            const locale = this.$route.params.locale;

            return {
                countryName: LOCALE_TO_COUNTRY_MAP[locale] || '',
                userAuth: this.getUserID ? 1 : 0,
                userId: this.getUserID ? this.getUserID : '',
            };
        },

        isVwoTestsEnabled() {
            if (!this.$store.state.pages?.vwoTestsEnabled) {
                return false;
            }

            const locales = this.$store.state.pages.vwoTestsEnabled;

            if (Array.isArray(locales)) {
                if (locales.includes('all')) return true;
                return locales.includes(this.locale);
            }

            return false;
        },

        vwoCheckAttempts() {
            return this.$store.state.pages.vwoCheckAttempts;
        },

        vwoCheckInterval() {
            return this.$store.state.pages.vwoCheckInterval;
        },
    },

    mounted() {
        if (this.isVwoTestsEnabled) {
            this.checkVwoEventStateAndPushGA();
        } else {
            window.vwoEventState = window.VWO_EVENT_STATE_CANCELED;
            this.pushGaEvents();
        }

        this.handleClick();
        this.handleURLHash();
        this.storeLocalUTM();
        this.storeSessionUTM();
    },

    beforeMount() {
        window.addEventListener('load', this.addLabelUTM);
    },

    beforeDestroy() {
        window.removeEventListener('load', this.addLabelUTM);
    },

    methods: {
        async deleteSensitiveQueryParams() {
            if (Object.keys(this.$route?.query).length) {
                this.$store.commit('trial/SET_QUERY_STRING_DATA', this.$route.query);

                const query = { ...this.$route.query };
                const expectedKeys = [
                    'osauid',
                    'aan',
                    'company',
                    'email',
                    'first_name',
                    'last_name',
                    'phone',
                    'country',
                    'currency',
                    'price',
                    'opportunity_id',
                    'tenantId',
                ];

                expectedKeys.forEach((key) => (delete query[key]));

                if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
                    await this.$router.replace({ query });
                }
            }
        },

        get6senseRedirect() {
            if (!sessionStorage.getItem('6senseRedirect')) return {};
            const redirectType = sessionStorage.getItem('6senseRedirect');
            return { Test_Cell_121: `WEB_45334.${redirectType}` };
        },

        checkVwoEventStateAndPushGA() {
            clearTimeout(this.vwoCheckTimerId);

            if (window.willRedirectionOccurByVWO) return;

            const vwoFlag = window.sessionStorage.getItem(window.VWO_EVENT_SESSION_KEY);

            if (vwoFlag === 'true') {
                window.vwoEventState = window.VWO_EVENT_STATE_HANDLED;
                this.pushGaEvents();
                return;
            }

            const state = window.vwoEventState;

            this.vwoAttempts++;

            if (!state || state === window.VWO_EVENT_STATE_PENDING) {
                // back to the regular flow in this case
                if (this.vwoAttempts >= this.vwoCheckAttempts) {
                    window.vwoEventState = window.VWO_EVENT_STATE_HANDLED;
                    this.pushGaEvents();
                    return;
                }

                this.vwoCheckTimerId = setTimeout(() => this.checkVwoEventStateAndPushGA(), this.vwoCheckInterval);

                return;
            }

            // if we have reached this point, we have a scheduled redirect or events already processed => don't do anything
            if ([window.VWO_EVENT_STATE_DONE_REDIRECT, window.VWO_EVENT_STATE_HANDLED].includes(state)) {
                window.vwoEventState = window.VWO_EVENT_STATE_HANDLED;
                return;
            }

            window.vwoEventState = window.VWO_EVENT_STATE_HANDLED;

            this.pushGaEvents();
        },

        async pushGaEvents() {
            window.sessionStorage.removeItem(window.VWO_EVENT_SESSION_KEY);

            if (window.willRedirectionOccurByVWO) return;
            const senseRedirects = this.page?.settings?.redirectPer6sense || [];
            if (!senseRedirects.length) this.$store.dispatch('pages/setLoading', false);

            // we clear potential first_name and last_name params to prevent them from being sent to facebook
            await this.deleteSensitiveQueryParams();

            const entityGAParams = {
                ...this.getMergedDlGaParams,
                ...this.getRcGaParams,
            };

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'BOGO',
                touchpoint: 'website',
                ...this.dimensions,
                ...this.getUserInfo,
                ...entityGAParams,
                ...this.get6senseRedirect(),
            });

            this.initAnonymousGATrackerDelayed(new Date());

            this.events.forEach((item) => window.dataLayer.push(item));
        },

        addLabelUTM() {
            if (!this.getSessionUTM.length) {
                return;
            }
            Array.from(document.getElementsByTagName('a')).forEach((element: HTMLAnchorElement): void => {
                const url = this.setQueryString(element.href);
                if (url) element.setAttribute('href', url);
            });
        },

        handleClick(): void {
            document.body.addEventListener('click', (e: any) => {
                // WEB-42890: Support dynamically injected surl links from the api content
                if (e.target.dataset?.surl) {
                    e.preventDefault();
                    const surl = JSON.parse(e.target.dataset.surl);
                    const options = this.storeOptionsSURL(surl);
                    this.redirectToCleverbridge(options);
                    return;
                }
                if (e.target.dataset?.event) {
                    window.dataLayer.push({
                        eventLabel: e.target.href,
                        ...e.target.dataset,
                    });
                }
            });
        },

        handleURLHash(): void {
            const hash = this.$route.hash.replace('/', ''); // TODO: why replace?
            if (!hash) {
                return;
            }

            if (window.location.hash !== hash) {
                window.location.hash = hash;
            }

            document.addEventListener('readystatechange', () => this.scrollToSlice(hash));
        },

        scrollToSlice(hash: string): void {
            if (document.readyState !== 'complete') {
                return;
            }

            // hash can contain virtually anything so querySelector can throw an invalid selector error
            let el = null;
            try {
                el = document.querySelector(hash);
            } catch (e) {
                // NO OP
            }

            if (!el) {
                return;
            }

            const scrollMargin = Array.from(document.querySelectorAll('.directive-v-sticky'))
                .map((sticky: HTMLElement) => sticky.offsetHeight || 0)
                .reduce((acc: number, next: number) => acc + next, 0);

            window.scroll({
                top: el.getBoundingClientRect().top + window.scrollY - scrollMargin,
                behavior: 'smooth',
            });
        },

        storeLocalUTM(): void {
            if (!window.localStorage) {
                return;
            }

            const utmContent = new URL(window.location.href).searchParams.get('utm_content');
            const allowedKeys = new Set(['smart', 'Smart', 'SmartDisplay']);

            if (allowedKeys.has(utmContent)) {
                localStorage.setItem('utm_content', utmContent);
            }
        },

        storeSessionUTM(): void {
            if (!window.sessionStorage) {
                return;
            }

            const searchParams = new URL(window.location.href).searchParams;

            const labels = [
                'SFDCCampaignStatus',
                'SFDCCampaignID',
                'utm_campaign',
                'utm_content',
                'utm_medium',
                'utm_source',
                'utm_term',
                'x-audience',
                'x-opp-id',
                'x-tenant_id',
                'x-wave',
            ];

            labels
                .map((key) => ({ key, val: searchParams.get(key) }))
                .filter((pair) => Boolean(pair.val))
                .forEach((x) => sessionStorage.setItem(x.key, x.val));
        },

        initAnonymousGATrackerDelayed(startedAt: any): void {
            // Universal Google Analytics now require a tracker name for its calls: `ga("gtm5.send", ...)`
            // For some 3rd party libs (Onetrust, maybe more)
            // it's necessary to have an unnamed tracker: `ga("send", ...)`
            // As GTM doesn't fire a `ga_loaded` event of any kind, we must check it in a timer callback

            const MAX_DETECT_TIME = 10000; // ms
            const DETECT_INTERVAL = 100; // ms
            const CURRENT:any = new Date();

            if (window.ga && window.ga.loaded && window.ga.getAll()[0]) {
                const defaultTracker = window.ga.getAll()[0];
                const opts = {
                    cookieDomain: 'auto',
                    allowLinker: true,
                };
                window.ga('create', defaultTracker.get('trackingId'), opts);
            } else if (CURRENT - startedAt > MAX_DETECT_TIME) {
                // It took too long, we just give up
                // return;
            } else {
                setTimeout(() => this.initAnonymousGATrackerDelayed(startedAt), DETECT_INTERVAL);
            }
        },

        convertResourceCenterAudience(audiences) {
            if (audiences.length > 1) return 'Split';
            if (audiences.some((x) => x.slug === 'homeusers')) return 'Consumer';
            if (audiences.some((x) => x.slug === 'customers')) return 'Corporate';
            if (audiences.some((x) => x.slug === 'partners')) return 'Cloud';
            return null;
        },
    },
});
</script>
