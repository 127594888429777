export default {
    namespaced: true,

    state: () => ({
        items: {},
    }),

    actions: {
        setJobs(store, jobs) {
            store.commit('setJobs', jobs);
        },
    },

    mutations: {
        setJobs(state, jobs) {
            state.items = jobs.data;
        },
    },
};
